.bg {
  height: 100vh;
  background-image: url("../../public/gym-background.jpg");

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
}
.cursor {
  cursor: pointer;
}
.logo {
  margin-top: 50px;
}

.glassContainer {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 80vh;
  width: 30vw;
  background: rgba(255, 255, 255, 0.7);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border-radius: 10px;

  @media only screen and (max-width: 320px) {
    width: 80vw;
    height: fit-content;
    hr {
      margin-bottom: 0.3rem;
    }
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 360px) {
    width: 80vw;
    height: fit-content;
    h4 {
      font-size: small;
    }
  }
  @media only screen and (min-width: 411px) {
    width: 80vw;
    height: fit-content;
  }

  @media only screen and (min-width: 768px) {
    width: 80vw;
    height: fit-content;
  }
  @media only screen and (min-width: 1024px) {
    width: 70vw;
    height: fit-content;
  }
  @media only screen and (min-width: 1280px) {
    width: 30vw;
    height: fit-content;
  }
  .WelcomeText {
    margin: 1rem 0 2rem 0;
    letter-spacing: 0.2rem;
  }
  .input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 20%;
    width: 100%;
    .input {
      background: rgba(255, 255, 255, 0.15);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      border-radius: 2rem;
      width: 80%;
      height: 3rem;
      padding: 1rem;
      border: none;
      outline: none;
      color: #3c354e;
      font-size: 1rem;
      font-weight: bold;
      &:focus {
        display: inline-block;
        box-shadow: 0 0 0 0.2rem #b9abe0;
        backdrop-filter: blur(12rem);
        border-radius: 2rem;
      }
      &::placeholder {
        color: #b9abe099;
        font-weight: 100;
        font-size: 1rem;
      }
    }
  }
}
