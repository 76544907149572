.sidebar {
  position: fixed;
  left: 0;
  top: 64px;
  height: 100%;
  width: 10%;
  background: #11101d;
  z-index: 99;
  transition: all 0.5s ease;
  svg {
    color: white;
  }
}
.menu-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-radius: 0;
  width: 100%;
  padding: 8px 0;
}
.mobile-sidebar {
  width: 100%;
  background: #11101d;
  z-index: 99;
  transition: all 0.5s ease;
  border-top: 1px silver solid;
  svg {
    color: white;
  }
}
.sidebar.open {
  width: 250px;
}
.sidebar.open input {
  padding: 0 20px 0 50px;
  width: 100%;
}
.sidebar input {
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  outline: none;
  height: 50px;
  width: 100%;
  width: 50px;
  border: none;
  transition: all 0.5s ease;
  background: #1d1b31;
}
.menu-list-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  border-radius: 0;
  width: 100%;
}
.menu-text {
  font-size: 20px;
  font-weight: 400;
  white-space: nowrap;
  transition: 0.4s;
  color: inherit;
  text-align: center;
  margin: auto 10px;
}
.logo-text {
  font-size: 30px;
  font-weight: 400;
  white-space: nowrap;
  transition: 0.4s;
  color: white;
}
.logout {
  cursor: pointer;
}
.menu-item-container:hover,
.selected {
  background-color: whitesmoke;
  transition: all 1s ease;
  color: black;
  svg,
  span {
    color: black;
  }
}
