.sale-summary {
  padding: 10px;
  .table-header {
    th {
      color: #2596be;
    }
  }
  .collected {
    margin-top: 20px;
  }
  h2 {
    margin-bottom: 0;
  }
}
