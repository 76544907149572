.brand-name {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 700;
  background: inherit;
}

.nav-container {
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  padding: 0 16px;
  font-size: large;
  font-weight: 500;
}
.tool-bar {
  min-height: 0;
}
