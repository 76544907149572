.left-panel {
  padding: 20px 8px;
  display: flex;
  border-right: 1px solid #e3e3e3;
  justify-content: center;
  height: 100vh;
  h2,
  p {
    margin: 0;
  }
  .star-icon {
    color: yellow;
  }
  .active-btn {
    cursor: auto;
    width: 60%;
  }
  .field-label {
    font-size: 1.2rem;
    color: #f5425d;
  }
  .bold {
    color: #328da8;
  }
}
