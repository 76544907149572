.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: none;
}
.delete-icon {
  position: absolute !important;
  top: 0;
  right: 0;
}
.checkin-icon {
  position: absolute !important;
  bottom: 0;
  right: 0;
}
.card-container {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 10px;
}
.card-container:hover {
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
